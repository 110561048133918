// **Redux Imports
import {createSlice, createAsyncThunk, createAction} from "@reduxjs/toolkit"
import API from '@src/service'
import {AccessToken} from '@utils'
import END_POINTS from '@configs/apiEndPointsConfig'

const initialState = {
    login: {
        success: null,
        isLoading: false,
        token: '',
        message: '',
        errorBag: []
    },
    logout: {
        success: null,
        isLoading: false,
        message: '',
        errorBag: []
    },
    forceLogout: {
        showModal: false
    },
    forgotPassword: {
        success: null,
        isLoading: false,
        message: '',
        data : []
    },
    resetPassword: {
        success: null,
        isLoading: false,
        message: '',
        errorBag: []
    },
    resetPasswordVerifyLink: {
        success: null,
        isLoading: false,
        message: '',
        data : []
    }
}

/*
* Action for unauthorized modal on 401
* status code
*
* @author Akshay Mahajan
*/
export const unAuthorizedModal = createAction('UnAuthorizedModal')

/*
* Action for revert all states
*
* @author Akshay Mahajan
*/
export const revertAll = createAction('REVERT_ALL')

/*
* Handle login
*
* @rturn {object}
* @author Akshay Mahajan
*/
export const login = createAsyncThunk('auth/login', async params => {
    const response = await API.post(END_POINTS.AUTH.LOGIN, params)
    return {
        status: response.status,
        token: response?.data?.token || '',
        message: response.message,
        errors: response?.errors || []
    }
})

/*
* Handle Logout
*
* @rturn {object}
* @author Akshay Mahajan
*/
export const logout = createAsyncThunk('auth/logout', async params => {
    const response = await API.post(END_POINTS.AUTH.LOGOUT, params)
    return {
        status: response.status,
        message: response.message
    }
})

/*
* Handle login
*
* @rturn {object}
* @author Akshay Mahajan
*/
export const forgotPassword = createAsyncThunk('auth/forgotPassword', async params => {
    const response = await API.post(END_POINTS.AUTH.FORGOT_PASSWORD, params)
    return {
        status: response.status,
        message: response.message,
        errors: response?.errors || []
    }
})


/*
* Reset password verify link
*
* @rturn {object}
* @author Akshay Mahajan
*/
export const resetPasswordVerifyLink = createAsyncThunk('auth/resetPassword/verify/link', async params => {
    const response = await API.post(END_POINTS.AUTH.RESET_PASSWORD_VERIFY_LINK, params)
    return {
        status: response.status,
        message: response.message,
        data : response.data
    }
})

/*
* Reset password
*
* @rturn {object}
* @author Akshay Mahajan
*/
export const resetPassword = createAsyncThunk('auth/resetPassword', async params => {
    const response = await API.post(END_POINTS.AUTH.RESET_PASSWORD, params)
    return {
        status: response.status,
        message: response.message,
        data : response.data
    }
})

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(unAuthorizedModal, (state) => {
                state = {
                    ...initialState,
                    forceLogout: {
                        ...initialState.forceLogout,
                        showModal: true
                    }
                }
                return state
            })
            .addCase(revertAll, (state) => {
                const token = state.login.token
                state = {
                    ...initialState,
                    login: {
                        ...initialState.login,
                        token
                    }
                }
                return state
            })

            .addCase(login.pending, (state, action) => {
                state.login = {
                    ...initialState.login,
                    isLoading: true
                }
            })
            .addCase(login.fulfilled, (state, action) => {
                state.login = {
                    ...state.login,
                    isLoading: false,
                    token: action.payload.token,
                    message: action.payload.message,
                    errorBag: action.payload.errors,
                    success: action.payload.status || false
                }
                if (action.payload.token !== '') {
                    //Set local storage
                    AccessToken.set(action.payload.token)
                }
            })
            .addCase(login.rejected, (state, action) => {
                state.login = {
                    ...state.login,
                    isLoading: false,
                    success: false,
                    message: action.payload.message
                }
            })

            .addCase(logout.pending, (state, action) => {
                state.logout = {
                    ...initialState.logout,
                    isLoading: true
                }
            })
            .addCase(logout.fulfilled, (state, action) => {
                const {status, message} = action.payload
                if (status) {
                    //Remove token
                    AccessToken.remove()
                    state.login = initialState.login
                }
                state.logout = {
                    ...initialState.logout,
                    isLoading: false,
                    success: status,
                    message
                }
            })
            .addCase(logout.rejected, (state, action) => {
                state.logout = {
                    ...state.logout,
                    isLoading: false,
                    success: false,
                    message: action.payload.message
                }
            })

            .addCase(forgotPassword.pending, (state, action) => {
                state.forgotPassword = {
                    ...initialState.forgotPassword,
                    isLoading: true
                }
            })
            .addCase(forgotPassword.fulfilled, (state, action) => {
                state.forgotPassword = {
                    ...state.forgotPassword,
                    isLoading: false,
                    message: action.payload.message,
                    errorBag: action.payload.errors,
                    success: action.payload.status || false
                }
            })
            .addCase(forgotPassword.rejected, (state, action) => {
                state.forgotPassword = {
                    ...state.forgotPassword,
                    isLoading: false,
                    success: false,
                    message: action.payload.message
                }
            })

            .addCase(resetPassword.pending, (state, action) => {
                state.resetPassword = {
                    ...initialState.resetPassword,
                    isLoading: true
                }
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.resetPassword = {
                    ...state.resetPassword,
                    isLoading: false,
                    message: action.payload.message,
                    data: action.payload.data,
                    success: action.payload.status || false
                }
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.resetPassword = {
                    ...state.resetPassword,
                    isLoading: false,
                    success: false,
                    message: action.payload.message
                }
            })

            .addCase(resetPasswordVerifyLink.pending, (state, action) => {
                state.resetPasswordVerifyLink = {
                    ...initialState.resetPasswordVerifyLink,
                    isLoading: true
                }
            })
            .addCase(resetPasswordVerifyLink.fulfilled, (state, action) => {
                state.resetPasswordVerifyLink = {
                    ...state.resetPasswordVerifyLink,
                    isLoading: false,
                    message: action.payload.message,
                    data: action.payload.data,
                    success: action.payload.status || false
                }
            })
            .addCase(resetPasswordVerifyLink.rejected, (state, action) => {
                state.resetPasswordVerifyLink = {
                    ...state.resetPasswordVerifyLink,
                    isLoading: false,
                    success: false,
                    message: action.payload.message
                }
            })

    }
})

export default authSlice.reducer