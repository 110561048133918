// ** React Imports
import {useEffect, useState} from "react"

// ** Custom Components
import Avatar from "@components/avatar"
// ** Third Party Components
import {
    Power
} from "react-feather"

import toast from 'react-hot-toast'
// ** Reactstrap Imports

import {
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem
} from "reactstrap"
import {useSelector, useDispatch} from 'react-redux'
import {logout} from "@views/auth/store"
import ModalConfirmation from "@views/auth/logout/Confirmation"
// ** Utils
import {capitalizeFirstLetter} from '@utils'

const UserDropdown = () => {
    const authStore = useSelector(state => state.auth.logout)
    const adminStore = useSelector(state => state.admin)

    const dispatch = useDispatch()
    const [openModal, setOpenModal] = useState(false)

    //Dispatch logout
    function _logout() {
        dispatch(
            logout()
        )
    }

    useEffect(() => {
        if (authStore.success) {
            setOpenModal(false)
            toast.success(capitalizeFirstLetter(authStore.message))
            setTimeout(() => {
                window.location = process.env.REACT_APP_DEFAULT_PUBLIC_ROUTE
            }, 200)
        }
    }, [authStore.success])

    return (
        <>
            <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
                <DropdownToggle
                    tag="a"
                    className="nav-link dropdown-user-link"
                    onClick={(e) => e.preventDefault()}
                >
                    <div className="user-nav d-sm-flex d-none">
                        <span className="user-name fw-bold">{adminStore?.loginDetail?.info?.name}</span>
                        <span className="user-status">Admin</span>
                    </div>
                    <Avatar
                        content={adminStore?.loginDetail?.info?.name || 'Admin'}
                        initials
                        imgHeight="40"
                        imgWidth="40"
                    />
                </DropdownToggle>
                <DropdownMenu end>
                    <DropdownItem tag="a" onClick={(e) => setOpenModal(true)}>
                        <Power size={14} className="me-75"/>
                        <span className="align-middle">Logout</span>
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
            <ModalConfirmation
                setOpenModal={setOpenModal}
                openModal={openModal}
                _logout={_logout}
                isLoading={authStore?.isLoading}
            />
        </>
    )
}

export default UserDropdown
