import { DefaultRoute } from "../router/routes";
import CryptoJS from "crypto-js";
import CountryList from "@configs/countryListConfig";

// ** Third Party Components
import moment from "moment";

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return DefaultRoute;
  if (userRole === "client") return "/access-control";
  return "/login";
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#EE16681a", // for option hover bg-color
    primary: "#EE1668", // for selected option bg-color
    neutral10: "#EE1668", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
});

// ** Capital first character
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const LocalStorage = {
  /**
   * Determine if browser supports local storage.
   *
   * @return {boolean}
   */
  isSupported() {
    return typeof Storage !== "undefined";
  },

  /**
   * Check if key exists in local storage.
   *
   * @param {string} key
   * @return {boolean}
   */
  has(key) {
    return localStorage.hasOwnProperty(key);
  },

  /**
   * Retrieve an object from local storage.
   *
   * @param {string} key
   * @return {any}
   */
  get(key) {
    const item = localStorage.getItem(key);

    if (typeof item !== "string") return item;

    if (item === "undefined") return undefined;

    if (item === "null") return null;

    // Check for numbers and floats
    if (/^'-?\d{1,}?\.?\d{1,}'$/.test(item)) return Number(item);

    // Check for numbers in scientific notation
    if (/^'-?\d{1}\.\d+e\+\d{2}'$/.test(item)) return Number(item);

    // Check for serialized objects and arrays
    if (item[0] === "{" || item[0] === "[") return JSON.parse(item);

    return item;
  },

  /**
   * Save some value to local storage.
   *
   * @param {string} key
   * @param {any} value
   * @return {void}
   */
  set(key, value) {
    if (typeof key !== "string") {
      throw new TypeError(
        `localStorage: Key must be a string. (reading '${key}')`
      );
    }

    if (typeof value === "object" || typeof value === "array") {
      value = JSON.stringify(value);
    }

    localStorage.setItem(key, value);
  },

  /**
   * Remove element from local storage.
   *
   * @param {string} key
   * @return {void}
   */
  remove(key) {
    localStorage.removeItem(key);
  },
};

/**
 * Access token
 *
 * This is object functions are collection of
 * access token actions
 */
export const AccessToken = {
  get() {
    return LocalStorage.get(process.env.REACT_APP_AUTH_TOKEN_KEY);
  },
  set(token) {
    LocalStorage.set(process.env.REACT_APP_AUTH_TOKEN_KEY, token);
  },
  remove() {
    LocalStorage.remove(process.env.REACT_APP_AUTH_TOKEN_KEY);
  },
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => AccessToken.get();
export const getUserData = () => JSON.parse(localStorage.getItem("userData"));

/**
 * Remember me credentials
 *
 * This is object functions are collection of
 * remember me actions
 */
export const RememberMe = (function () {
  const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;
  const REMEMBER_ME_KEY = process.env.REACT_APP_REMEMBER_KEY;
  return {
    /**
     * Get remember me detail
     *
     * @return {object}
     * @author Akshay Mahajan
     */
    get() {
      const storageData = LocalStorage.get(REMEMBER_ME_KEY);
      if (storageData) {
        const bytes = CryptoJS.AES.decrypt(storageData, ENCRYPTION_KEY);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return {};
    },
    /**
     * Set remember me detail
     *
     * @return void
     * @author Akshay Mahajan
     */
    set(data) {
      const ciphertext = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        ENCRYPTION_KEY
      ).toString();
      LocalStorage.set(REMEMBER_ME_KEY, ciphertext);
    },
    /**
     * Remove remember me detail
     *
     * @return void
     * @author Akshay Mahajan
     */
    remove() {
      LocalStorage.remove(REMEMBER_ME_KEY);
    },
  };
})();

export const getClearObject = (obj = {}) => {
  const newObj = {};
  Object.keys(obj).map((key) => {
    const item = obj[key];
    if (typeof item === "boolean") {
      newObj[key] = item;
    } else if (typeof item === "number") {
      newObj[key] = item;
    } else if (typeof item === "string") {
      if (item) {
        newObj[key] = item;
      }
    } else if (Array.isArray(item)) {
      if (item.length) {
        newObj[key] = item;
      }
    } else if (typeof item === "object") {
      // if object is type of date
      if (item instanceof Date) {
        if (!isNaN(item)) {
          newObj[key] = item;
        }
      } else if (Object?.keys(item || {})?.length) {
        newObj[key] = item;
      }
    }
  });
  return newObj;
};

/**
 * Remove record from array
 *
 * @param {Array} arr
 * @param {Array} deleteArr
 * @param {Object} options
 * @returns will remove record from the arr based on values
 * of deleteArr based on key or callback passed in options
 */
export const removeRecordsFromArray = (arr = [], deleteArr, options) => {
  const { key, callback } = options;
  const deletedFullArray = [];

  arr = arr.filter((item) => {
    const id = callback ? callback(item) : item[key];
    if (deleteArr.includes(id)) {
      deletedFullArray.push(item);
      return false;
    }
    return true;
  });

  return {
    result: arr,
    deleted: deletedFullArray.length,
    deletedFullArray,
  };
};

/**
 * Remove record from array
 *
 * @param {object} data
 * @param {string} path
 * @returns returns requested data from object
 */
export const getValueFromPath = function (data = {}, path) {
  path = path.replace(/\[(\w+)\]/g, ".$1");
  path = path.replace(/^\./, "");
  const a = path.split(".");
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (k in (data || {})) {
      data = data[k];
    } else {
      return "";
    }
  }
  return data;
};

/**
 * Country Info
 *
 * Get country information
 */
export const CountryInfo = (function () {
  return {
    /**
     * Get remember me detail
     *
     * @return {object}
     * @author Akshay Mahajan
     */
    get(countryCode) {
      const list = Object.values(CountryList);
      if (countryCode === undefined) {
        return list;
      }
      if (typeof countryCode !== "string") {
        return undefined;
      }
      const code = countryCode.toUpperCase();
      return Object.prototype.hasOwnProperty.call(CountryList, code)
        ? CountryList[code]
        : undefined;
    },
  };
})();

/**
 * To fixed digits
 *
 * Adds fractional digits if value is non integer
 *
 * @param {Number} value
 * @param {Number} Integer number fractionDigits
 * @return {Number}
 * @author Akshay Mahajan
 */
export const toFixedDigit = (value, fractionDigits = 2) => {
  return Number.isInteger(value) ? value : value.toFixed(fractionDigits);
};

export const abbreviateNumber = (n, arrayIgnoreFormats = []) => {
  /*eslint-disable */
  if (n < 1e3) return n;
  if (!arrayIgnoreFormats.includes("K")) {
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  }
  if (!arrayIgnoreFormats.includes("M")) {
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  }
  if (!arrayIgnoreFormats.includes("B")) {
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  }
  if (!arrayIgnoreFormats.includes("T")) {
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  }
  return n;
  /*eslint-enable */
};

/**
 * Get querystring value by key
 *
 * @param {String} queryStringKey
 * @return {String}
 * @author Akshay Mahajan
 */
export const getQueryStringValueByKey = (queryStringKey) => {
  const queryString = window.location.search;
  const parameters = new URLSearchParams(queryString);
  return parameters.get(queryStringKey);
};

/**
 * Get URL parameters
 *
 * @return {object}
 * @author Akshay Mahajan
 */
export function getUrlParams() {
  const params = new URLSearchParams(window.location.search);
  return Array.from(params.keys()).reduce(
    (acc, val) => ({ ...acc, [val]: params.get(val) }),
    {}
  );
}

/**
 * Get appended url params
 *
 * @return {object}
 * @author Akshay Mahajan
 */
export function getAppendUrlParams(_object) {
  const params = new URLSearchParams(window.location.search);
  const prevParams = Array.from(params.keys()).reduce(
    (acc, val) => ({ ...acc, [val]: params.get(val) }),
    {}
  );
  return {
    ...prevParams,
    ..._object,
  };
}

/**
 * Convert to date and time
 *
 * @return {object} timestamp
 * @author Akshay Mahajan
 * return {string}
 */
export const convertToDateTime = (timestamp) => {
  return moment(timestamp).format(process.env.REACT_APP_DATE_TIME_FORMAT);
};

/**
 * Convert to date
 *
 * @return {object} timestamp
 * @author Akshay Mahajan
 * return {string}
 */
export const convertToDate = (timestamp) => {
  return moment(timestamp).format(process.env.REACT_APP_DATE_FORMAT);
};

/**
 * Convert to date and month
 *
 * @return {object} timestamp
 * @author Akshay Mahajan
 * return {string}
 */
export const convertToDateMonth = (timestamp) => {
  return moment(timestamp).format(process.env.REACT_APP_DATE_MONTH_FORMAT);
};

/**
 * Convert to time
 *
 * @return {object} timestamp
 * @author Vijay Ahir
 * return {string}
 */
export const convertToTime = (timestamp) => {
  return moment(timestamp).format(process.env.REACT_APP_TIME_FORMAT);
};

export const getDateBasedOnPeriod = () => {
  return {
    daily: {
      start_date: moment().startOf("day").utc().format(),
      end_date: moment().endOf("day").utc().format(),
    },
    weekly: {
      start_date: moment().startOf("day").utc().subtract(6, "days").format(),
      end_date: moment().endOf("day").utc().format(),
    },
    monthly: {
      start_date: moment().startOf("day").utc().subtract(1, "month").format(),
      end_date: moment().endOf("day").utc().format(),
    },
    yearly: {
      start_date: moment().startOf("day").utc().subtract(1, "year").format(),
      end_date: moment().endOf("day").utc().format(),
    },
  };
};

export const getUtcDateForFilter = (date) => {
  if (date?.length) {
    return [
      moment(date[0]).startOf("day").utc().format(),
      moment(date[1]).endOf("day").utc().format(),
    ];
  } else {
    return moment(date).utc().format();
  }
};
