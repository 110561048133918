// ** Redux Imports
import API from '@src/service'
import END_POINTS from '@configs/apiEndPointsConfig'
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

export const getListData = createAsyncThunk('violatedPost/getData', async (params) => {
    const response = await API.get(END_POINTS.PRIVATE.VIOLATED_POSTS.LIST, params)
    return {
        status: response.status,
        message: response.message,
        totalItems: response?.data?.totalItems || 0,
        totalPages: response?.data?.totalPages || 1,
        currentPage: response?.data?.currentPage || 1,
        data: response?.data.records || [],
        params
    }
})

export const restorePost = createAsyncThunk('violatedPost/restorePost', async (params) => {
    const {
        postId
    } = params
    const response = await API.put(END_POINTS.PRIVATE.VIOLATED_POSTS.RESTORE_POST.replace(':postId', postId))
    return {
        status: response.status,
        message: response?.message || '',
        errors: response?.errors || [],
        requestData: {
            postId
        }
    }
})

const initialState = {
    list: {
        isLoading: false,
        status: null,
        totalItems: 0,
        totalPages: 1,
        currentPage: 1,
        data: [],
        params: {}
    },
    restorePost: {
        success: null,
        isLoading: false,
        message: ''
    }
}
export const violatedPostSlice = createSlice({
    name: 'violatedPost',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListData.pending, (state) => {
                state.list = {
                    ...state.list,
                    isLoading: true
                }
            })
            .addCase(getListData.fulfilled, (state, action) => {
                state.list = {
                    ...state.list,
                    isLoading: false,
                    status: action.payload.status,
                    totalItems: action.payload.totalItems,
                    totalPages: action.payload.totalPages,
                    currentPage: action.payload.currentPage,
                    params: action.payload.params,
                    data: action.payload.data
                }
            })

            .addCase(restorePost.pending, (state, action) => {
                state.restorePost = {
                    ...state.restorePost,
                    isLoading: true
                }
            })
            .addCase(restorePost.fulfilled, (state, action) => {

                const {
                    payload: {
                        status,
                        message,
                        errors,
                        requestData: {
                            postId
                        }
                    }
                } = action

                const newList = state?.list.data.filter((item) => {
                    return item.id !== postId
                })
                state.list = {
                    ...state.list,
                    data: newList
                }

                state.restorePost = {
                    ...state.restorePost,
                    success: status,
                    isLoading: false,
                    message,
                    errors
                }
            })
            .addCase(restorePost.rejected, (state, action) => {
                state.restorePost = {
                    ...state.restorePost,
                    isLoading: false
                }
            })
    }
})
export default violatedPostSlice.reducer
