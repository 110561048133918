import {User, Package, AlertOctagon, Circle, GitPullRequest, GitMerge, Home, DollarSign} from "react-feather"

export default [
    {
        id: 'dashboards',
        title: 'Dashboards',
        icon: <Home size={20}/>,
        navLink: "/dashboard"
    },
    {
        id: "users",
        title: "User",
        icon: <User size={20}/>,
        navLink: "/users"
    },
    {
        id: "subscriptions",
        title: "Subscription",
        icon: <Package size={20}/>,
        navLink: "/subscriptions"
    },
    {
        id: 'reports',
        title: 'Report',
        icon: <AlertOctagon size={20}/>,
        children: [
            {
                id: 'reportComment',
                title: 'Comment',
                icon: <Circle size={12}/>,
                navLink: '/reports/comment'
            },
            {
                id: 'reportPost',
                title: 'Post',
                icon: <Circle size={12}/>,
                navLink: '/reports/post'
            },
            {
                id: 'reportUser',
                title: 'User',
                icon: <Circle size={12}/>,
                navLink: '/reports/user'
            }
        ]
    },
    {
        id: 'bankRequests',
        title: 'Bank Request',
        icon: <GitPullRequest size={20}/>,
        navLink: '/bank-requests'
    },
    {
        id: 'referrals',
        title: 'Referral',
        icon: <GitMerge size={20}/>,
        navLink: '/referrals'
    },
    {
        id: 'chargebacks',
        title: 'Chargeback',
        icon: <DollarSign size={20}/>,
        navLink: '/chargebacks'
    }
]
