/* eslint-disable comma-dangle */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable semi */
// ** React Imports
import {Fragment, lazy} from 'react'
import {Navigate} from 'react-router-dom'
// ** Layouts
import BlankLayout from '@layouts/BlankLayout'
import VerticalLayout from '@src/layouts/VerticalLayout'
import HorizontalLayout from '@src/layouts/HorizontalLayout'
import LayoutWrapper from '@src/@core/layouts/components/layout-wrapper'

// ** Route Components
import PublicRoute from '@components/routes/PublicRoute'

// ** Utils
import {isObjEmpty} from '@utils'

const getLayout = {
    blank: <BlankLayout/>,
    vertical: <VerticalLayout/>,
    horizontal: <HorizontalLayout/>,
}

// ** Document title
const TemplateTitle = `%s - ${process.env.REACT_APP_PROJECT_NAME}`

// ** Default Route\
const DefaultRoute = process.env.REACT_APP_DEFAULT_PUBLIC_ROUTE
// ** Public pages components
const Login = lazy(() => import('@views/auth/login/Login'))
const ResetPassword = lazy(() =>
    import('@views/auth/reset-password/ResetPassword')
)
const ForgotPassword = lazy(() =>
    import('@views/auth/forgot-password/ForgotPassword')
)
// ** Private pages components
const Users = lazy(() => import('@views/private/users/list/Index'))
const Reports = lazy(() => import('@views/private/reports/list/Index'))
const Dashboards = lazy(() => import('@views/private/dashboard/Index'))
const UserDetail = lazy(() => import('@views/private/users/detail/index'))
const Referrals = lazy(() => import('@views/private/referrals/list/Index'))
const Chargebacks = lazy(() => import('@views/private/chargebacks/list/Index'))
const ReleaseForm = lazy(() =>
    import('@views/private/release-forms/list/Index')
)
const BankRequests = lazy(() =>
    import('@views/private/bank-requests/list/Index')
)
const SeriesTaxForms = lazy(() =>
    import('@views/private/series-tax-forms/list/Index')
)
const Subscriptions = lazy(() =>
    import('@views/private/subscriptions/list/creator/Index')
)
const Suggestions = lazy(() => import('@views/private/suggestions/list/index'))
const EarningSpending = lazy(() => import('@views/private/earning-spending/list/Index'))
const ViolatedPosts = lazy(() => import('@views/private/violated-posts/list/Index'))
const Payouts = lazy(() => import('@views/private/payouts/list/Index'))
const AgencyMembers = lazy(() => import('@views/private/agency-members/list/Index'))
const Agency = lazy(() => import('@views/private/agency/list/Index'))

// ** Common pages components
const Error = lazy(() => import('@views/Error'))

//Public routes
const publicRoutes = [
    {
        path: '/',
        element: <Navigate replace to={DefaultRoute}/>
    },
    {
        path: '/login',
        index: true,
        element: <Login/>,
        meta: {
            layout: 'blank'
        }
    },
    {
        path: '/forgot-password',
        element: <ForgotPassword/>,
        meta: {
            layout: 'blank'
        }
    },
    {
        path: '/reset-password/:link',
        element: <ResetPassword/>,
        meta: {
            layout: 'blank'
        },
    },
]

//Private routes
const privateRoutes = [
    {
        path: '/agency/yumyhub',
        element: <AgencyMembers/>,
        meta: {
            restricted: true
        },
    },
    {
        path: '/agencies',
        element: <Agency/>,
        meta: {
            restricted: true
        },
    },
    {
        path: '/bank-requests',
        element: <BankRequests/>,
        meta: {
            restricted: true
        },
    },
    {
        path: '/chargebacks',
        element: <Chargebacks/>,
        meta: {
            restricted: true
        }
    },
    {
        path: '/dashboard',
        element: <Dashboards/>,
        meta: {
            restricted: true,
        },
    },
    {
        path: 'earning-spending',
        element: <EarningSpending/>,
        meta: {
            restricted: true
        },
    },
    {
        path: '/payouts',
        element: <Payouts/>,
        meta: {
            restricted: true
        },
    },
    {
        path: '/reports/:reportableType',
        element: <Reports/>,
        meta: {
            restricted: true
        },
    },
    {
        path: '/referrals',
        element: <Referrals/>,
        meta: {
            restricted: true
        },
    },
    {
        path: '/release-form',
        element: <ReleaseForm/>,
        meta: {
            restricted: true
        },
    },

    {
        path: '/subscriptions',
        element: <Subscriptions/>,
        meta: {
            restricted: true
        },
    },
    {
        path: '/suggestions',
        element: <Suggestions/>,
        meta: {
            restricted: true
        },
    },

    {
        path: 'series-tax-form',
        element: <SeriesTaxForms/>,
        meta: {
            restricted: true
        },
    },
    {
        path: '/users',
        element: <Users/>,
        meta: {
            restricted: true
        },
    },
    {
        path: '/users/:id/detail',
        element: <UserDetail/>,
        meta: {
            restricted: true
        },
    },
    {
        path: '/violated/posts',
        element: <ViolatedPosts/>,
        meta: {
            restricted: true
        },
    }
]

// ** Merge Routes
const Routes = [
    ...publicRoutes,
    ...privateRoutes,
    {
        //404
        path: '*',
        element: <Error/>,
        meta: {
            layout: 'blank',
        },
    },
]

const getRouteMeta = (route) => {
    if (isObjEmpty(route.element.props)) {
        if (route.meta) {
            return {routeMeta: route.meta}
        } else {
            return {}
        }
    }
}

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
    const LayoutRoutes = []

    if (Routes) {
        Routes.filter((route) => {
            let isBlank = false
            // ** Checks if Route layout or Default layout matches current layout
            if (
                (route.meta && route.meta.layout && route.meta.layout === layout) ||
                ((route.meta === undefined || route.meta.layout === undefined) &&
                    defaultLayout === layout)
            ) {
                const RouteTag = PublicRoute

                // ** Check for public or private route
                if (route.meta) {
                    route.meta.layout === 'blank' ? (isBlank = true) : (isBlank = false)
                }
                if (route.element) {
                    const Wrapper =
                        // eslint-disable-next-line multiline-ternary
                        isObjEmpty(route.element.props) && isBlank === false
                            ? // eslint-disable-next-line multiline-ternary
                            LayoutWrapper
                            : Fragment

                    route.element = (
                        <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
                            <RouteTag route={route}>{route.element}</RouteTag>
                        </Wrapper>
                    )
                }

                // Push route to LayoutRoutes
                LayoutRoutes.push(route)
            }
            return LayoutRoutes
        })
    }
    return LayoutRoutes
}

const getRoutes = (layout) => {
    const defaultLayout = layout || 'vertical'
    const layouts = ['vertical', 'horizontal', 'blank']
    const AllRoutes = []
    layouts.forEach((layoutItem) => {
        const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout)
        AllRoutes.push({
            path: '/',
            element: getLayout[layoutItem] || getLayout[defaultLayout],
            children: LayoutRoutes
        })
    })
    return AllRoutes
}

export {
    DefaultRoute,
    TemplateTitle,
    Routes,
    getRoutes,
    publicRoutes,
    privateRoutes
}
