// ** Redux Imports
import API from '@src/service'
import END_POINTS from '@configs/apiEndPointsConfig'
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

export const getCreatorList = createAsyncThunk('subscriptions/getCreatorList', async params => {
    const response = await API.get(END_POINTS.PRIVATE.SUBSCRIPTION.CREATOR_LIST, params)
    return {
        status: response.status,
        message: response.message,
        totalItems: response?.data?.totalItems || 0,
        totalPages: response?.data?.totalPages || 1,
        currentPage: response?.data?.currentPage || 1,
        data: response?.data.records || [],
        params
    }
})

export const getSubscriberList = createAsyncThunk('subscriptions/getSubscriberList', async params => {
    const response = await API.get(END_POINTS.PRIVATE.SUBSCRIPTION.SUBSCRIBER_LIST, params)
    return {
        status: response.status,
        message: response.message,
        totalItems: response?.data?.totalItems || 0,
        totalPages: response?.data?.totalPages || 1,
        currentPage: response?.data?.currentPage || 1,
        data: response?.data.records || [],
        params
    }
})

const initialState = {
    creatorList: {
        isLoading: false,
        status: null,
        totalItems: 0,
        totalPages: 1,
        currentPage: 1,
        data: [],
        params: {}
    },
    subscriberList: {
        isLoading: false,
        status: null,
        totalItems: 0,
        totalPages: 1,
        currentPage: 1,
        data: [],
        params: {}
    }
}

export const subscriptionsSlice = createSlice({
    name: 'subscriptions',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getCreatorList.pending, (state) => {
                state.creatorList = {
                    ...state.creatorList, isLoading: true
                }
            })
            .addCase(getCreatorList.fulfilled, (state, action) => {
                state.creatorList = {
                    isLoading: false,
                    status: action.payload.status,
                    totalItems: action.payload.totalItems,
                    totalPages: action.payload.totalPages,
                    currentPage: action.payload.currentPage,
                    params: action.payload.params,
                    data: action.payload.data
                }
            })
            .addCase(getSubscriberList.pending, (state) => {
                state.subscriberList = {
                    ...state.subscriberList, isLoading: true
                }
            })
            .addCase(getSubscriberList.fulfilled, (state, action) => {
                state.subscriberList = {
                    isLoading: false,
                    status: action.payload.status,
                    totalItems: action.payload.totalItems,
                    totalPages: action.payload.totalPages,
                    currentPage: action.payload.currentPage,
                    params: action.payload.params,
                    data: action.payload.data
                }
            })
    }
})

export default subscriptionsSlice.reducer
