// ** Redux Imports
import API from "@src/service";
import END_POINTS from "@configs/apiEndPointsConfig";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getData = createAsyncThunk("reports/getData", async (params) => {
  const response = await API.get(END_POINTS.PRIVATE.BANK_REQUEST.LIST, params);
  return {
    status: response.status,
    message: response.message,
    totalItems: response?.data?.totalItems || 0,
    totalPages: response?.data?.totalPages || 1,
    currentPage: response?.data?.currentPage || 1,
    data: response?.data.records || [],
    params,
  };
});

export const actionOnRequest = createAsyncThunk(
  "reports/actionOnRequest",
  async (params) => {
    const response = await API.post(
      END_POINTS.PRIVATE.BANK_REQUEST.ACTION_ON_REPORT,
      params
    );
    return {
      status: response.status,
      message: response.message,
      requestData: {
        ...params,
      },
    };
  }
);

export const updateBankRequest = createAsyncThunk(
  "reports/updateBankRequest",
  async (params) => {
    let response = await API.put(
      `${END_POINTS.PRIVATE.BANK_REQUEST.UPDATE}/${params?.id}`,
      params?.data
    );
    return {
      ...response,
      alternativeStatus: response?.status
        ? params?.data?.fill_alternative_form
        : !params?.data?.fill_alternative_form,
    };
  }
);

const initialState = {
  list: {
    isLoading: false,
    status: null,
    totalItems: 0,
    totalPages: 1,
    currentPage: 1,
    data: [],
    params: {},
  },
  action: {
    success: null,
    isLoading: false,
    message: "",
    errorBag: [],
  },
  update: {
    success: null,
    isLoading: false,
    message: "",
    errorBag: [],
  },
};
export const bankRequestsSlice = createSlice({
  name: "bankRequests",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getData.pending, (state) => {
        state.list = {
          ...state.list,
          isLoading: true,
        };
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.list = {
          isLoading: false,
          status: action.payload.status,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
          params: action.payload.params,
          data: action.payload.data,
        };
      })
      .addCase(actionOnRequest.pending, (state) => {
        state.action = {
          ...initialState.action,
          isLoading: true,
        };
      })
      .addCase(actionOnRequest.fulfilled, (state, action) => {
        const listState = state.list;
        let listData = listState.data;
        if (action.payload.status) {
          //Removed action report record
          listData = listData.filter((item) => {
            return (
              item.bank_detail.id !== action.payload.requestData?.bank_detail_id
            );
          });
        }
        state = {
          ...initialState,
          list: {
            ...listState,
            data: listData,
          },
          action: {
            ...state.action,
            isLoading: false,
            message: action.payload.message,
            data: action.payload.data,
            success: action.payload.status || false,
          },
        };
        return state;
      })
      .addCase(actionOnRequest.rejected, (state, action) => {
        state.action = {
          ...state.action,
          isLoading: false,
          success: false,
          message: action?.payload?.message,
        };
      })
      .addCase(updateBankRequest.pending, (state) => {
        state.update = {
          ...initialState.update,
          isLoading: true,
        };
      })
      .addCase(updateBankRequest.fulfilled, (state, action) => {
        state.update = {
          isLoading: false,
          success: action?.payload?.status,
          message: action?.payload?.message,
          errorBag: [],
        };
      })
      .addCase(updateBankRequest.rejected, (state, action) => {
        state.update = {
          ...state.update,
          isLoading: false,
          success: false,
          message: action?.payload?.message,
        };
      });
  },
});

export default bankRequestsSlice.reducer;
