// ** Redux Imports
import API from "@src/service";
import END_POINTS from "@configs/apiEndPointsConfig";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getAllUsersCount = createAsyncThunk(
  "dashboard/getAllUsersCount",
  async (params) => {
    const response = await API.get(
      END_POINTS.PRIVATE.DASHBOARD.ALL_USERS,
      params
    );
    return {
      status: response.status,
      data: response?.data,
      params,
    };
  }
);

export const getNewUsersAnalytics = createAsyncThunk(
  "dashboard/getNewUsersAnalytics",
  async (params) => {
    const response = await API.get(
      END_POINTS.PRIVATE.DASHBOARD.NEW_USERS,
      params
    );
    return {
      status: response.status,
      data: response?.data,
      params,
    };
  }
);
export const getTopCountriesUserCount = createAsyncThunk(
  "dashboard/getTopCountriesUserCount",
  async (params) => {
    const response = await API.get(
      END_POINTS.PRIVATE.DASHBOARD.USERS_FROM_TOP_COUNTRIES,
      params
    );
    return {
      status: response.status,
      data: response?.data,
      params,
    };
  }
);
export const getEarningsAnalytics = createAsyncThunk(
  "dashboard/getEarningsAnalytics",
  async (params) => {
    const response = await API.get(
      END_POINTS.PRIVATE.DASHBOARD.EARNINGS_ANALYTICS,
      params
    );
    return {
      status: response.status,
      data: response?.data,
      params,
    };
  }
);

const initialState = {
  allUsersCount: {
    isLoading: false,
    status: null,
    data: {},
  },
  newUsersAnalytics: {
    isLoading: false,
    status: null,
    data: {},
  },
  topCountriesUserCount: {
    isLoading: false,
    status: null,
    data: {},
  },
  earningsAnalytics: {
    isLoading: false,
    status: null,
    data: {},
  },
};
export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsersCount.pending, (state) => {
        state.allUsersCount = {
          ...state.allUsersCount,
          isLoading: true,
        };
      })
      .addCase(getAllUsersCount.fulfilled, (state, action) => {
        state.allUsersCount = {
          ...state.allUsersCount,
          isLoading: false,
          status: action.payload.status,
          data: action.payload.data,
        };
      })
      .addCase(getNewUsersAnalytics.pending, (state) => {
        state.newUsersAnalytics = {
          ...state.newUsersAnalytics,
          isLoading: true,
        };
      })
      .addCase(getNewUsersAnalytics.fulfilled, (state, action) => {
        state.newUsersAnalytics = {
          ...state.newUsersAnalytics,
          isLoading: false,
          status: action.payload.status,
          data: action.payload.data,
        };
      })
      .addCase(getTopCountriesUserCount.pending, (state) => {
        state.topCountriesUserCount = {
          ...state.topCountriesUserCount,
          isLoading: true,
        };
      })
      .addCase(getTopCountriesUserCount.fulfilled, (state, action) => {
        state.topCountriesUserCount = {
          ...state.topCountriesUserCount,
          isLoading: false,
          status: action.payload.status,
          data: action.payload.data,
        };
      })
      .addCase(getEarningsAnalytics.pending, (state) => {
        state.earningsAnalytics = {
          ...state.earningsAnalytics,
          isLoading: true,
        };
      })
      .addCase(getEarningsAnalytics.fulfilled, (state, action) => {
        state.earningsAnalytics = {
          ...state.earningsAnalytics,
          isLoading: false,
          status: action.payload.status,
          data: action.payload.data,
        };
      });
  },
});

export default dashboardSlice.reducer;
