// ** Redux Imports
import API from '@src/service'
import END_POINTS from '@configs/apiEndPointsConfig'
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

export const getData = createAsyncThunk('referrals/getData', async params => {
    const response = await API.get(END_POINTS.PRIVATE.REFERRAL.LIST, params)
    return {
        status: response.status,
        message: response.message,
        totalItems: response?.data?.totalItems || 0,
        totalPages: response?.data?.totalPages || 1,
        currentPage: response?.data?.currentPage || 1,
        data: response?.data.records || [],
        params
    }
})

const initialState = {
    list: {
        isLoading: false,
        status: null,
        totalItems: 0,
        totalPages: 1,
        currentPage: 1,
        data: [],
        params: {}
    }
}
export const referralsSlice = createSlice({
    name: 'referrals',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.pending, (state) => {
                state.list = {
                    ...state.list,
                    isLoading: true
                }
            })
            .addCase(getData.fulfilled, (state, action) => {
                state.list = {
                    isLoading: false,
                    status: action.payload.status,
                    totalItems: action.payload.totalItems,
                    totalPages: action.payload.totalPages,
                    currentPage: action.payload.currentPage,
                    params: action.payload.params,
                    data: action.payload.data
                }
            })
    }
})

export default referralsSlice.reducer
