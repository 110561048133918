/* eslint-disable object-shorthand */
/* eslint-disable prefer-const */
/* eslint-disable eqeqeq */
/* eslint-disable semi */
/* eslint-disable comma-dangle */
// ** Redux Imports
import API from "@src/service";
import END_POINTS from "@configs/apiEndPointsConfig";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { chargebacksSlice } from "../../chargebacks/store";
import { updateBankRequest } from "../../bank-requests/store";
import toast from "react-hot-toast";

export const getListData = createAsyncThunk("users/getData", async (params) => {
  const response = await API.get(END_POINTS.PRIVATE.USER.LIST, params);
  return {
    status: response.status,
    message: response.message,
    totalItems: response?.data?.totalItems || 0,
    totalPages: response?.data?.totalPages || 1,
    currentPage: response?.data?.currentPage || 1,
    data: response?.data.records || [],
    params,
  };
});
export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (params) => {
    const { userId } = params;
    const response = await API.deleteM(
      END_POINTS.PRIVATE.USER.DELETE.replace(":userId", userId)
    );
    return {
      status: response.status,
      message: response.message,
      data: response?.data,
      requestData: {
        ...params,
      },
    };
  }
);
export const getUser = createAsyncThunk("users/getUser", async (params) => {
  const { userId, mode } = params;
  const response = await API.get(
    END_POINTS.PRIVATE.USER.GET.replace(":userId", userId)
  );
  return {
    status: response.status,
    message: response?.message || "",
    selectedUser: response?.user || {},
    requestData: {
      ...params,
      mode,
    },
  };
});
export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (params, { getState }) => {
    const { userId, mode } = params;
    delete params.userId;
    delete params.mode;
    const response = await API.put(
      END_POINTS.PRIVATE.USER.PUT.replace(":userId", userId),
      params
    );
    return {
      status: response.status,
      message: response?.message || "",
      errors: response?.errors || [],
      userList: getState()?.users?.list,
      requestData: {
        ...params,
        userId,
        mode,
      },
    };
  }
);
export const changePassword = createAsyncThunk(
  "users/changePassword",
  async (params) => {
    const { userId } = params;
    delete params.userId;
    const response = await API.post(
      END_POINTS.PRIVATE.USER.CHANGE_PASSWORD.replace(":userId", userId),
      params
    );
    return {
      status: response.status,
      message:
        response?.message ||
        (response.status ? "Password changed successfully" : ""),
      requestData: {
        ...params,
        userId,
      },
    };
  }
);

export const getSplitPercentage = createAsyncThunk(
  "users/getSpitPercentage",
  async (params) => {
    const { userId } = params;
    const response = await API.get(
      END_POINTS.PRIVATE.USER.SPLIT_PERCENTAGE.GET.replace(":userId", userId)
    );
    return {
      status: response.status,
      message: response?.message || "",
      data: response?.user,
    };
  }
);

export const updateSplitPercentage = createAsyncThunk(
  "users/updateSpitPercentage",
  async (params, { getState }) => {
    const { userId, accessMode, payload } = params;
    const response = await API.put(
      END_POINTS.PRIVATE.USER.SPLIT_PERCENTAGE.PUT.replace(":userId", userId),
      payload
    );
    return {
      status: response.status,
      message: response?.message || "",
      errors: response?.errors || [],
      requestData: {
        ...params,
        userId,
        accessMode,
        payload,
      },
    };
  }
);

export const freezeUnfreezeUserAccount = createAsyncThunk(
  "reports/freezeUnfreezeUserAccount",
  async (params) => {
    const { userId, is_user_account_freeze } = params.payload;
    const response = await API.patch(
      END_POINTS.PRIVATE.USER.UPDATE_FREEZE_STATUS.replace(":userId", userId),
      {
        is_user_account_freeze,
      }
    );
    return {
      status: response.status,
      message: response.message,
      requestData: {
        ...params,
      },
    };
  }
);

export const manageCreatorStatus = createAsyncThunk(
  "reports/manageCreatorStatus",
  async (params) => {
    const { id, creator_status, isAgency } = params;
    const response = await API.patch(
      END_POINTS.PRIVATE.USER.CHANGE_CREATOR_STATUS.replace(":userId", id),
      {
        creator_status,
      }
    );
    let res = { status: response.status, message: response.message };
    if (isAgency) {
      res.idv_verification_status = response.status
        ? creator_status
        : !creator_status;
    } else {
      res.is_creator = response.status ? creator_status : !creator_status;
    }
    return res;
  }
);

export const resetUserMeataData = createAsyncThunk(
  "reports/resetUserMeataData",
  async (params) => {
    console.log(params);
    const { id, payload = {} } = params;
    const response = await API.patch(
      END_POINTS.PRIVATE.USER.RESET_USER_METADATA.replace(":userId", id),
      payload
    );
    if (response.status) {
      toast.success("User settings has been reset successfully.");
    } else {
      toast.error("Unable to reset settings, Please try again after sometime.");
    }
    return response;
  }
);

const initialState = {
  list: {
    isLoading: false,
    status: null,
    totalItems: 0,
    totalPages: 1,
    currentPage: 1,
    data: [],
    params: {},
  },
  view: {
    detail: {
      mode: "view",
      isLoading: false,
      status: null,
      selectedUser: {},
    },
  },
  delete: {
    success: null,
    isLoading: false,
    message: "",
  },
  update: {
    success: null,
    isLoading: false,
    message: "",
  },
  changePassword: {
    success: null,
    isLoading: false,
    message: "",
  },
  getSplitPercentage: {
    success: null,
    isLoading: false,
    message: "",
    data: {},
  },
  updateSplitPercentage: {
    success: null,
    isLoading: false,
    message: "",
  },
  freezeUnfreezeUserAccount: {
    success: null,
    isLoading: false,
    message: "",
  },
  manageCreatorStatus: {
    success: null,
    isLoading: false,
    message: "",
    error: "",
  },
  resetMetaDataDetails: {
    isLoading: false,
    data: {},
    error: "",
  },
};
export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    onSuccessUpdateFreezeStatusInList: (state, action) => {
      const userList = state?.list.data;
      let newList = userList.map((item) => {
        if (item?.id === action.payload.userId) {
          return {
            ...item,
            is_user_account_freeze: action.payload.isAccountFrozen,
          };
        } else {
          return item;
        }
      });
      state.list = {
        ...state.list,
        data: newList,
      };
    },
    onSuccessUpdateFreezeStatusInViewDetail: (state, action) => {
      state.view = {
        ...state.view,
        detail: {
          ...state.view.detail,
          selectedUser: {
            ...state.view.detail.selectedUser,
            is_user_account_freeze: action.payload.isAccountFrozen,
          },
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListData.pending, (state) => {
        state.list = {
          ...state.list,
          isLoading: true,
        };
      })
      .addCase(getListData.fulfilled, (state, action) => {
        state.list = {
          ...state.list,
          isLoading: false,
          status: action.payload.status,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
          params: action.payload.params,
          data: action.payload.data,
        };
      })

      .addCase(deleteUser.pending, (state) => {
        state.delete = {
          ...initialState.delete,
          isLoading: true,
        };
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        const listState = state.list;
        let listData = listState.data;
        if (action.payload.status) {
          //Removed action report record
          listData = listData.filter((item) => {
            return item.id !== action.payload.requestData?.userId;
          });
        }
        state = {
          ...initialState,
          list: {
            ...listState,
            data: listData,
          },
          delete: {
            ...state.delete,
            isLoading: false,
            message: action.payload.message,
            success: action.payload.status || false,
          },
        };
        return state;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.delete = {
          ...state.delete,
          isLoading: false,
          success: false,
          message: action?.payload?.message,
        };
      })

      .addCase(getUser.pending, (state, action) => {
        const {
          meta: {
            arg: { mode },
          },
        } = action;
        state.view = {
          ...state.view,
          detail: {
            ...state.view.detail,
            isLoading: true,
            mode,
          },
        };
      })
      .addCase(getUser.fulfilled, (state, action) => {
        const {
          payload: {
            status,
            message,
            selectedUser,
            requestData: { mode },
          },
        } = action;
        state.view = {
          ...state.view,
          detail: {
            ...state.view.detail,
            isLoading: false,
            status,
            message,
            selectedUser,
            mode,
          },
        };
      })
      .addCase(getUser.rejected, (state, action) => {
        state.view = {
          ...state.view,
          detail: {
            ...state.view.detail,
            isLoading: false,
          },
        };
      })

      .addCase(updateUser.pending, (state, action) => {
        state.update = {
          ...state.update,
          isLoading: true,
        };
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        const {
          payload: {
            status,
            message,
            errors,
            userList,
            requestData: {
              userId,
              mode,
              name,
              display_name,
              username,
              email,
              mobile_number,
              is_suggested_user,
              platform_fee_percentage,
            },
          },
        } = action;

        let newList = userList?.data?.map((item) => {
          if (item?.id === userId) {
            return {
              ...item,
              is_suggested_user: is_suggested_user,
              platform_fee_percentage : platform_fee_percentage ?? item?.platform_fee_percentage,
            };
          } else {
            return item;
          }
        });

        if (mode === "view") {
          state.view = {
            ...state.view,
            detail: {
              ...state.view.detail,
              selectedUser: {
                ...state.view.detail.selectedUser,
                name,
                display_name,
                username,
                email,
                mobile_number,
              },
            },
          };
        }
        state.update = {
          ...state.update,
          success: status,
          message,
          isLoading: false,
          errors,
        };
        state.list = {
          ...state?.list,
          data: newList,
        };
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.update = {
          ...state.update,
          isLoading: false,
        };
      })

      .addCase(changePassword.pending, (state, action) => {
        state.changePassword = {
          ...state.changePassword,
          isLoading: true,
        };
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        const {
          payload: { status, message },
        } = action;
        state.changePassword = {
          ...state.changePassword,
          success: status,
          message,
          isLoading: false,
        };
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.changePassword = {
          ...state.changePassword,
          isLoading: false,
        };
      })

      .addCase(getSplitPercentage.pending, (state, action) => {
        state.getSplitPercentage = {
          ...state.getSplitPercentage,
          isLoading: true,
          data: {},
        };
      })
      .addCase(getSplitPercentage.fulfilled, (state, action) => {
        const {
          payload: { status, message, data },
        } = action;
        state.getSplitPercentage = {
          ...state.getSplitPercentage,
          isLoading: false,
          message,
          success: status,
          data,
        };
      })
      .addCase(getSplitPercentage.rejected, (state, action) => {
        state.getSplitPercentage = {
          ...state.getSplitPercentage,
          isLoading: false,
        };
      })

      .addCase(updateSplitPercentage.pending, (state, action) => {
        state.updateSplitPercentage = {
          ...state.updateSplitPercentage,
          isLoading: true,
        };
      })
      .addCase(updateSplitPercentage.fulfilled, (state, action) => {
        const {
          payload: {
            status,
            message,
            errors,
            requestData: { userId, accessMode, payload },
          },
        } = action;

        if (accessMode === "userListing") {
          const userList = state?.list.data;
          let newList = userList.map((item) => {
            if (item?.id === userId) {
              return {
                ...item,
                payment_split_percentage: payload.paymentSplitPercentage,
              };
            } else {
              return item;
            }
          });
          state.list = {
            ...state.list,
            data: newList,
          };
        } else if (accessMode === "userDetail") {
          state.view.detail.selectedUser = {
            ...state.view.detail.selectedUser,
            payment_split_percentage: payload.paymentSplitPercentage,
          };
        }
        state.updateSplitPercentage = {
          ...state.updateSplitPercentage,
          success: status,
          isLoading: false,
          message,
          errors,
        };
      })
      .addCase(updateSplitPercentage.rejected, (state, action) => {
        state.updateSplitPercentage = {
          ...state.updateSplitPercentage,
          isLoading: false,
        };
      })

      .addCase(freezeUnfreezeUserAccount.pending, (state) => {
        state.freezeUnfreezeUserAccount = {
          ...initialState.freezeUnfreezeUserAccount,
          isLoading: true,
        };
      })
      .addCase(freezeUnfreezeUserAccount.fulfilled, (state, action) => {
        state.freezeUnfreezeUserAccount = {
          ...state.freezeUnfreezeUserAccount,
          isLoading: false,
          message: action.payload.message,
          success: action.payload.status || false,
          requestData: action.payload.requestData,
        };
      })
      .addCase(updateBankRequest.fulfilled, (state, action) => {
        return {
          ...state,
          view: {
            detail: {
              ...state.view.detail,
              selectedUser: {
                ...state.view.detail.selectedUser,
                bank_detail: {
                  ...state.view.detail.selectedUser?.bank_detail,
                  fill_alternative_form: action.payload?.alternativeStatus,
                },
              },
            },
          },
        };
      })
      .addCase(manageCreatorStatus.pending, (state) => {
        state.manageCreatorStatus = {
          ...initialState.manageCreatorStatus,
          isLoading: true,
        };
      })
      .addCase(manageCreatorStatus.fulfilled, (state, action) => {
        return {
          ...state,
          view: {
            ...state.view,
            detail: {
              ...state.view.detail,
              selectedUser: {
                ...state.view.detail.selectedUser,
                is_creator: action?.payload?.is_creator,
                idv_verification_status: action?.payload?.idv_verification_status,
              },
            },
          },
          manageCreatorStatus: {
            isLoading: false,
            message: action.payload.message,
            success: action.payload.status || false,
            error: "",
          },
        };
      })
      .addCase(manageCreatorStatus.rejected, (state, action) => {
        state.manageCreatorStatus = {
          ...state.manageCreatorStatus,
          isLoading: false,
          success: false,
          message: action?.payload?.message,
        };
      })
      .addCase(resetUserMeataData.pending, (state, action) => {
        return {
          ...state,
          resetMetaDataDetails: {
            ...initialState?.resetMetaDataDetails,
            isLoading: true,
          },
        };
      })
      .addCase(resetUserMeataData.fulfilled, (state, action) => {
        return {
          ...state,
          resetMetaDataDetails: {
            isLoading: false,
            data: action.payload,
            error: "",
          },
        };
      })
      .addCase(resetUserMeataData.rejected, (state, action) => {
        return {
          ...state,
          resetMetaDataDetails: {
            isLoading: false,
            data: {},
            error: action.payload,
          },
        };
      });
  },
});
export const {
  onSuccessUpdateFreezeStatusInList,
  onSuccessUpdateFreezeStatusInViewDetail,
} = usersSlice.actions;
export default usersSlice.reducer;
