// ** Redux Imports
import API from '@src/service'
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import END_POINTS from '@configs/apiEndPointsConfig'

export const getW8FormList = createAsyncThunk('seriesTaxForms/getW8FormList', async params => {
    const response = await API.get(END_POINTS.PRIVATE.SERIES_TAX_FORMS.W8.LIST, params)
    return {
        status: response.status,
        message: response.message,
        totalItems: response?.data?.totalItems || 0,
        totalPages: response?.data?.totalPages || 1,
        currentPage: response?.data?.currentPage || 1,
        data: response?.data.records || [],
        params
    }
})
export const getW9FormList = createAsyncThunk('seriesTaxForms/getW9FormList', async params => {
    const response = await API.get(END_POINTS.PRIVATE.SERIES_TAX_FORMS.W9.LIST, params)
    return {
        status: response.status,
        message: response.message,
        totalItems: response?.data?.totalItems || 0,
        totalPages: response?.data?.totalPages || 1,
        currentPage: response?.data?.currentPage || 1,
        data: response?.data.records || [],
        params
    }
})

const initialState = {
    w8: {
        list: {
            isLoading: false,
            status: null,
            totalItems: 0,
            totalPages: 1,
            currentPage: 1,
            data: [],
            params: {}
        }
    },
    w9: {
        list: {
            isLoading: false,
            status: null,
            totalItems: 0,
            totalPages: 1,
            currentPage: 1,
            data: [],
            params: {}
        }
    }
}

export const seriesTaxFormsSlice = createSlice({
    name: 'seriesTaxForms',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getW8FormList.pending, (state) => {
                state.w8.list = {
                    ...state.w8.list,
                    isLoading: true
                }
            })
            .addCase(getW8FormList.fulfilled, (state, action) => {
                state.w8.list = {
                    isLoading: false,
                    status: action.payload.status,
                    totalItems: action.payload.totalItems,
                    totalPages: action.payload.totalPages,
                    currentPage: action.payload.currentPage,
                    params: action.payload.params,
                    data: action.payload.data
                }
            })

            .addCase(getW9FormList.pending, (state) => {
                state.w9.list = {
                    ...state.w9.list,
                    isLoading: true
                }
            })
            .addCase(getW9FormList.fulfilled, (state, action) => {
                state.w9.list = {
                    isLoading: false,
                    status: action.payload.status,
                    totalItems: action.payload.totalItems,
                    totalPages: action.payload.totalPages,
                    currentPage: action.payload.currentPage,
                    params: action.payload.params,
                    data: action.payload.data
                }
            })
    }
})

export default seriesTaxFormsSlice.reducer
