// ** Reducers Imports
import layout from './layout'
import navbar from './navbar'
import auth from '@views/auth/store/index'
import users from '@views/private/users/store/index'
import admin from '@views/private/admin/store/index'
import reports from '@views/private/reports/store/index'
import payouts from '@views/private/payouts/store/index'
import referrals from '@views/private/referrals/store/index'
import dashboard from '@views/private/dashboard/store/index'
import chargebacks from '@views/private/chargebacks/store/index'
import releaseForms from '@views/private/release-forms/store/index'
import bankRequests from '@views/private/bank-requests/store/index'
import subscriptions from '@views/private/subscriptions/store/index'
import agencyYumyhub from '@views/private/agency-members/store/index'
import agencies from '@views/private/agency/store/index'
import violatedPosts from '@views/private/violated-posts/store/index'
import seriesTaxForms from '@views/private/series-tax-forms/store/index'
import earningSpending from '@views/private/earning-spending/store/index'


const rootReducer = {
    agencyYumyhub,
    agencies,
    auth,
    admin,
    bankRequests,
    chargebacks,
    dashboard,
    earningSpending,
    navbar,
    layout,
    payouts,
    reports,
    referrals,
    releaseForms,
    seriesTaxForms,
    subscriptions,
    users,
    violatedPosts
}

export default rootReducer
