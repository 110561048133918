// ** Redux Imports
import API from '@src/service'
import END_POINTS from '@configs/apiEndPointsConfig'
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

export const getData = createAsyncThunk('reports/getData', async params => {
    const response = await API.get(END_POINTS.PRIVATE.REPORT.LIST, params)
    return {
        status: response.status,
        message: response.message,
        totalItems: response?.data?.totalItems || 0,
        totalPages: response?.data?.totalPages || 1,
        currentPage: response?.data?.currentPage || 1,
        data: response?.data.records || [],
        params
    }
})

export const actionOnReport = createAsyncThunk('reports/actionOnReport', async params => {
    const {reportId, actionType} = params
    const response = await API.post((END_POINTS.PRIVATE.REPORT.ACTION).replace(':reportId', reportId), {
        action_taken: actionType.toString()
    })
    return {
        status: response.status,
        message: response.message,
        data: response?.data,
        requestData: {
            ...params
        }
    }
})

const initialState = {
    list: {
        isLoading: false,
        status: null,
        totalItems: 0,
        totalPages: 1,
        currentPage: 1,
        data: [],
        params: {}
    },
    action: {
        success: null,
        isLoading: false,
        message: '',
        errorBag: []
    }
}
export const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.pending, (state) => {
                state.list = {
                    ...state.list,
                    isLoading: true
                }
            })
            .addCase(getData.fulfilled, (state, action) => {
                state.list = {
                    isLoading: false,
                    status: action.payload.status,
                    totalItems: action.payload.totalItems,
                    totalPages: action.payload.totalPages,
                    currentPage: action.payload.currentPage,
                    params: action.payload.params,
                    data: action.payload.data
                }
            })
            .addCase(actionOnReport.pending, (state) => {
                state.action = {
                    ...initialState.action,
                    isLoading: true
                }
            })
            .addCase(actionOnReport.fulfilled, (state, action) => {
                const listState = state.list
                let listData = listState.data
                if (action.payload.status) {
                    //Removed action report record
                    listData = listData.filter(item => {
                        return item.id !== action.payload.requestData?.reportId
                    })
                }
                state = {
                    ...initialState,
                    list: {
                        ...listState,
                        data: listData
                    },
                    action: {
                        ...state.action,
                        isLoading: false,
                        message: action.payload.message,
                        data: action.payload.data,
                        success: action.payload.status || false
                    }
                }
                return state
            })
            .addCase(actionOnReport.rejected, (state, action) => {
                state.action = {
                    ...state.action,
                    isLoading: false,
                    success: false,
                    message: action?.payload?.message
                }
            })
    }
})

export default reportsSlice.reducer
