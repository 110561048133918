//API END POINTS
const END_POINTS = {
  AUTH: {
    LOGIN: "auth/login",
    LOGOUT: "auth/logout",
    FORGOT_PASSWORD: "auth/forgot/password",
    RESET_PASSWORD: "auth/reset/password",
    RESET_PASSWORD_VERIFY_LINK: "auth/reset/password/verify/link",
  },
  PRIVATE: {
    AGENCY_YUMYHUB: {
      LIST: "agency-yumyhub/user/list",
    },
    AGENCY: {
      LIST: "agencies/user/list",
    },
    BANK_REQUEST: {
      LIST: "bank-details/list",
      ACTION_ON_REPORT: "bank-details/status-action",
      UPDATE: "bank-details",
    },
    CHARGEBACK: {
      LIST: "netbilling-payment/list",
      GET_SINGLE: "netbilling-payment/detail/:id",
      FREEZE_UNFREEZE_USER_ACCOUNT: "netbilling-payment/update-status",
    },
    DASHBOARD: {
      ALL_USERS: "dashboard/all/users/count",
      NEW_USERS: "dashboard/new/users/count",
      USERS_FROM_TOP_COUNTRIES: "dashboard/top-countries/users/count",
      EARNINGS_ANALYTICS: "dashboard/earning/analytics",
    },
    EARNING_SPENDING: {
      LIST: "financial/list",
      AGENCIES: "financial/agencies",
    },
    PAYOUT: {
      PREVIOUS: {
        LIST: "payouts/previous-transactions",
      },
      UPCOMING: {
        LIST: "payouts/upcoming-transactions",
      },
    },
    REPORT: {
      LIST: "reports/list",
      ACTION: "reports/:reportId/action",
    },
    REFERRAL: {
      LIST: "users/referral-list",
    },
    RELEASE_FORM: {
      LIST: "release-forms/list",
      GET: "release-forms/detail/:id",
      ACTION_ON_REQUEST: "release-forms/update-release-form-status",
    },
    SERIES_TAX_FORMS: {
      W8: {
        LIST: "w8-forms/list",
      },
      W9: {
        LIST: "w9-forms/list",
      },
    },
    SUBSCRIPTION: {
      CREATOR_LIST: "users/user-creator-list",
      SUBSCRIBER_LIST: "users/user-subscription-expired-active-list",
    },
    USER: {
      LIST: "users/list",
      DELETE: "users/:userId",
      GET: "users/:userId",
      PUT: "users/:userId",
      CHANGE_PASSWORD: "users/:userId/change-password",
      SPLIT_PERCENTAGE: {
        GET: "users/split-percentage/:userId",
        PUT: "users/split-percentage/:userId",
      },
      UPDATE_FREEZE_STATUS: "users/:userId/freeze-status",
      CHANGE_CREATOR_STATUS: "users/:userId/creator-status",
      RESET_USER_METADATA: "users/:userId/reset-metadata",
    },
    VIOLATED_POSTS: {
      LIST: "violated/post/list",
      RESTORE_POST: "violated/post/:postId/restore",
    },
  },
};

export default END_POINTS;
