/* eslint-disable semi */
/* eslint-disable comma-dangle */
import {
    Users,
    Package,
    AlertOctagon,
    Circle,
    GitPullRequest,
    GitMerge,
    Home,
    File,
    DollarSign,
    FileText,
    AlertTriangle,
    ArrowLeft
} from 'react-feather'
export default [
    {
        id: 'dashboards',
        title: 'Dashboard',
        icon: <Home size={20}/>,
        navLink: '/dashboard'
    },
    {
        id: 'agency-members',
        title: 'Agency (YumyHub)',
        icon: <Users size={20}/>,
        navLink: '/agency/yumyhub'
    },
    {
        id: 'agency-members',
        title: 'Agency',
        icon: <Users size={20}/>,
        navLink: '/agencies'
    },
    {
        id: 'bankRequests',
        title: 'Bank Requests',
        icon: <GitPullRequest size={20}/>,
        navLink: '/bank-requests'
    },
    {
        id: 'chargebacks',
        title: 'Chargebacks',
        icon: <DollarSign size={20}/>,
        navLink: '/chargebacks'
    },
    {
        id: 'earning_spending',
        title: 'Earnings/Spendings',
        icon: <DollarSign size={20}/>,
        navLink: '/earning-spending'
    },
    {
        id: 'payouts',
        title: 'Payouts',
        icon: <ArrowLeft size={20}/>,
        navLink: '/payouts'
    },
    {
        id: 'release-form',
        title: 'Release Form',
        icon: <File size={20}/>,
        navLink: '/release-form'
    },
    {
        id: 'referrals',
        title: 'Referrals',
        icon: <GitMerge size={20}/>,
        navLink: '/referrals'
    },
    {
        id: 'reports',
        title: 'Reports',
        icon: <AlertOctagon size={20}/>,
        children: [
            {
                id: 'reportComment',
                title: 'Comment',
                icon: <Circle size={12}/>,
                navLink: '/reports/comment',
            },
            {
                id: 'reportPost',
                title: 'Post',
                icon: <Circle size={12}/>,
                navLink: '/reports/post'
            },
            {
                id: 'reportUser',
                title: 'User',
                icon: <Circle size={12}/>,
                navLink: '/reports/user'
            },
        ],
    },
    {
        id: 'subscriptions',
        title: 'Subscriptions',
        icon: <Package size={20}/>,
        navLink: '/subscriptions'
    },
    {
        id: 'series_tax_forms',
        title: 'Series Tax Forms',
        icon: <FileText size={20}/>,
        navLink: '/series-tax-form'
    },
    {
        id: 'users',
        title: 'Users',
        icon: <Users size={20}/>,
        navLink: '/users'
    },
    {
        id: 'violated-posts',
        title: 'Violated Posts',
        icon: <AlertTriangle size={20}/>,
        navLink: '/violated/posts'
    }
]
